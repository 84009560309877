import React from 'react';
import classNames from 'classnames';
import { Onlive } from '../../types';

export function OnLiveLink(props: { item: Onlive }) {
  const { item } = props;
  return (
    <a className="onlive-link" target="_blank" href={`https://www.showroom-live.com/${item.roomUrl}`}>
      <figure className="image is-16by9">
        <img src={item.roomImage} />
      </figure>
      <div>
          <a className="onlive-link-title has-text-weight-bold is-margin-top-1 is-margin-bottom-0-5">{item.roomName}</a>
          <div className="onlive-link-captions is-flex">
              <span>
                  <img
                      src="data:image/svg+xml,%3Csvg width='28' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23FF4D4D' stroke-width='1.6' x='.8' y='.8' width='26.4' height='14.4' rx='2'/%3E%3Cpath d='M2,1 C1.44771525,1 1,1.44771525 1,2 L1,14 C1,14.5522847 1.44771525,15 2,15 L26,15 C26.5522847,15 27,14.5522847 27,14 L27,2 C27,1.44771525 26.5522847,1 26,1 L2,1 Z M2,0 L26,0 C27.1045695,-2.02906125e-16 28,0.8954305 28,2 L28,14 C28,15.1045695 27.1045695,16 26,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M5,13 L5,3 L6.43258427,3 L6.43258427,11.5674157 L9.26966292,11.5674157 L9.26966292,13 L5,13 Z M10.42603,13 L10.42603,3 L11.8586142,3 L11.8586142,13 L10.42603,13 Z M18.2958802,3 L16.2453184,13 L14.9812734,13 L12.9447566,3 L14.4616105,3 L15.5992509,9.98033708 L15.6273408,9.98033708 L16.7790262,3 L18.2958802,3 Z M19.3398876,13 L19.3398876,3 L23.6095506,3 L23.6095506,4.34831461 L20.7724719,4.34831461 L20.7724719,7.28370787 L23.244382,7.28370787 L23.244382,8.63202247 L20.7724719,8.63202247 L20.7724719,11.5674157 L23.6095506,11.5674157 L23.6095506,13 L19.3398876,13' fill='%23FF0000' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E"
                      alt="FREE" width="24" height="12" className="css-1jp54it e134v6kj0" />
              </span>
              <div>
                  <span>{item.startTime}</span>
              </div>
          </div>
      </div>
    </a>
  );
}
