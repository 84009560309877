const lodashToSafeInteger = require('lodash.tosafeinteger');
type Func<T> = () => T;

export function toSafeInteger(value: any) {
    return lodashToSafeInteger(value);
}

export function tryCatch<T>(handler: Func<T>): [null | Error, T] {
    try {
        return [null, handler()];
    } catch (err) {
        return [err] as any;
    }
}