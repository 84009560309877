import React, { useState } from 'react';
import classNames from 'classnames';

interface ContestFaqProps {
  question: string;
  answer: any;
}

export function ContestFaq({question, answer}: ContestFaqProps) {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={classNames('contest-faq', {
        'is-active': open,
      })}
    >
      <div className="contest-faq-header" onClick={toggle}>
        <div className="contest-faq-question">
          <span className="icon"><i className="far fa-question-circle"></i></span>
        </div>
        <div className="contest-faq-toggle">
          <span className="icon">
            <i className="fas fa-chevron-down" />
          </span>
        </div>
        <div className="contest-faq-name has-text-weight-bold">
          <span className="title">{question}</span>
        </div>
      </div>
      <div className="contest-faq-body">
        {answer}
      </div>
    </div>
  );

  function toggle() {
    setOpen(!open);
  }
}
