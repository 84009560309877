import React from 'react';
import {SectionTitle} from '../SectionTitle';
import {ImageState, LazyImageFull} from "react-lazy-images";
import classNames from "classnames";
import styled from "styled-components";

interface ISectionProps {
  color: string;
  profileNode: React.ReactNode;
  titleNode: React.ReactNode;
  textNode: React.ReactNode;
}

const ImageBox = styled.figure`
    > img {
    opacity: 0;
    transition: opacity 0.5s;
    &.is-load-success {
      opacity: 1;
    }
    }
`;


export function InterviewSection(props: ISectionProps & React.AnchorHTMLAttributes<HTMLDivElement>) {
  return (
    <section className="section as-interview-section has-background-white">
      <div className="container">
        <SectionTitle className="title" title={'Voice'} subtitle={<>2019グランプリからのメッセージ</>} />
        <div className="columns is-24 is-variable">
          <div className="column is-5">
            <div>
              {props.profileNode}
            </div>
            <ImageBox className="image">
              <LazyImageFull observerProps={{threshold: 0.01, rootMargin: "150px 0px"}}  src="/img/2019winner.jpg" alt="2019グランプリ">
                {({ imageProps, imageState, ref }) => {
                  if (imageState === ImageState.NotAsked) {
                    return <div ref={ref} />;
                  }
                  return (
                      <img
                          {...imageProps}
                          className={classNames({
                            'is-load-success': imageState === ImageState.LoadSuccess,
                          })}
                          ref={ref}
                      />
                  );
                }}
              </LazyImageFull>
            </ImageBox>
          </div>
          <div className="column is-7">
            <div>
              {props.titleNode}
              {props.textNode}
            </div>
            <figure className="image is-hidden-mobile">
              <svg viewBox="0 0 640 640">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect fill={props.color} x="0" y="0" width="640" height="640" />
                </g>
              </svg>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
