import React from 'react';
import styled from 'styled-components';
import { LineSection, ConceptSection, Announcement } from '@2020-contest-web/components/src/components';
import { VideoTag } from 'react-video-tag';
import useMedia from 'use-media-easy';
import { LandingSections } from '../../components';

export function TopApp() {
  const [matches] = useMedia({ query: { maxWidth: 600 } });
  return (
    <main className="TopApp">
      {/* Hero */}
      <Hero className="hero is-fullheight is-dark">
        <div className="hero-head">
          <nav className="navbar as-header is-hero" />
        </div>
        <div className="hero-body has-announcement" style={{ zIndex: 10 }}>
          <Announcement href="https://www.frecam.jp/?utm_source=frecam2020&utm_medium=operation&utm_campaign=20210403&utm_content=hero">
            2021年のエントリーがスタート！
          </Announcement>
          <div className="container">
            <figure className="image as-logo">
              <img src={'/img/logo.svg'} alt={process.env.REACT_APP_WEBSITE_NAME} />
            </figure>
          </div>
        </div>
        <div className="hero-cover" />
        <div className="hero-movie">
          <figure className="image">
            <VideoTag
              poster={matches ? '/img/bg_mobile.jpg' : '/img/bg.jpg'}
              className="has-ratio"
              src={
                matches
                  ? 'https://s3-ap-northeast-1.amazonaws.com/s3.age-entertainment.jp/movie/0302_frecam_mobile.mp4'
                  : 'https://s3-ap-northeast-1.amazonaws.com/s3.age-entertainment.jp/movie/0302_frecam.mp4'
              }
              autoPlay
              muted={true}
              playsInline
              loop
            />
          </figure>
        </div>
      </Hero>

      {/* Concept */}
      <LineSection src="/img/1208.svg" type="frecam" alt="今年も開催決定! 日本一の新入生を決めるコンテスト" />
      <ConceptSection>
        <h1 className="title has-text-white is-size-3-mobile">日本最大級のコンテスト</h1>
        <p style={{ marginBottom: 30 }}>
          FRESH CAMPUS CONTEST（通称：フレキャン）とは、大学新入生を対象とした日本最大級のコンテストです。
          <br />
         2020年12月06日にEX THEATER ROPPONGIで表彰式が行われ、受賞者が決定しました。
        </p>
        <a
          className="button is-medium is-fullwidth is-light is-link"
          href={'#award'}
          target="_blank"
          rel="noopener noreferrer"
        >
          2020年の受賞者を見る
        </a>
      </ConceptSection>
      <LandingSections />
    </main>
  );
}

const Hero = styled.section`
  background: transparent;
  .image.as-logo img {
    max-width: 480px;
    width: 80%;
    margin: 0 auto;
  }
  .buttons.as-hero-buttons {
    max-width: 180px;
    margin: 10px auto 0;
    flex-direction: column;
    .button {
      width: 100%;
    }
  }
  .hero-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .hero-movie {
    position: absolute;
    overflow: hidden;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    video {
      -webkit-transform: translateX(-50%);
      left: 50%;
      max-height: initial;
      max-width: initial;
      height: calc(100vh + 100px);
      min-width: 100%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      z-index: 0;
    }
  }
`;
