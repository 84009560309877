import React from 'react';

export function LineSection(props: React.ImgHTMLAttributes<HTMLImageElement> & {type: "misscircle" | "frecam"}) {
    return (
        <section className="section as-line-section has-angle-outside-bottom has-angle-outside-top">
            <div className="container">
                <figure className="image">
                    <img src={props.src} alt={props.alt} width={1024} />
                    <figcaption className="is-margin-top-1-mobile">
                        <div className="buttons is-right">
                            <a href="/posts/2020-12-05" className={`button is-rounded is-medium is-${props.type}`} style={{border: "2px solid white"}}>※ 表彰式の詳細はこちら</a>
                        </div>
                    </figcaption>
                </figure>
            </div>
        </section>
    );
}
