import React from 'react';
import styled from "styled-components";


interface ArticleHeroSectionProps {

}

export function ArticleHeroSection(props: ArticleHeroSectionProps & React.ImgHTMLAttributes<HTMLImageElement>) {
    return (
        <section className="section" style={{ padding: 0, position: 'relative' }}>
            <BackgroundImg>
                <img {...props} width="120%" style={{ filter: 'blur(10px)' }} />
            </BackgroundImg>
            <div className="container">
                <HeroImg className="1by1 has-text-centered">
                    <img {...props} width={646} height={646} />
                </HeroImg>
            </div>
        </section>
    )
}

const BackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: -20px;
  right: -20px;
  max-height: 75%;
  overflow: hidden;

  img {
    width: 100%;
    transform: translateY(-40%);
    top: 50%;
  }
`;

const HeroImg = styled.figure`
  img {
    box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);
  }
`;
