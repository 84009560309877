import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';

interface MediaBoxesProps {
  children: any;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function MediaBoxes(props: MediaBoxesProps) {
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    const f = async () => {
      setLoading(true);
      await delay(100);
      setLoading(false);
    };
    f();
  }, []);
  if (loading) {
    return null;
  }
  return (
    <Swiper
      {...{
        mousewheel: {
          invert: true,
        },
        autoplay: {
          delay: 5000,
        },
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 18,
        freeMode: true,
      }}
    >
      {props.children}
    </Swiper>
  );
}
