import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import { Link } from 'react-navi';
import { ArticleHeroSection } from '@2020-contest-web/components/src/components/';
export default compose(
  mount({
    '/nakagawa-kureha': route({
      getView: () => InterviewBagOfNakagawaApp,
      title: `【2019年フレキャン】グランプリ中川紅葉さんのバッグの中身を大公開  👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`,
    }),
    '/saito-rina': route({
      getView: () => InterviewBagOfSaitoApp,
      title: `【2019年フレキャン】準グランプリ斉藤里奈さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`,
    }),
    '/mochizuki-youmu': route({
      getView: () => InterviewBagOfMochizukiApp,
      title: `【2019年フレキャン】準グランプリ望月優夢さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`,
    }),
  }),
);

const InterviewBagOfNakagawaApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`【2019年フレキャン】グランプリ中川紅葉さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/bag/nakagawa-hero.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/bag/nakagawa-kureha`} property="og:url" />
      </Helmet>
      <ArticleHeroSection src="/img/interview/bag/nakagawa-hero.jpg" />

      <section className="section" style={{ paddingTop: '2rem', paddingBottom: 0 }}>
        <div className="container" style={{ maxWidth: 640 }}>
          <div className="tags" style={{ marginBottom: 0 }}>
            <p className="tag is-primary has-text-weight-bold">Special Contents</p>
          </div>
          <h1 className="title" style={{ lineHeight: 1.5 }}>
            【2019年フレキャン】
            <br />
            グランプリ中川紅葉さんに聞いてみた！バッグの中身も大公開👜 👀
          </h1>
          <div className="notification">
            2019年フレキャンの中川紅葉さんに突撃インタビュー🎤
            <br />
            現在ハマっていることや、大学生活の目標について伺いました。
            さらにバッグの中からお気に入りのアイテムも大公開！👜 ✨
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 640 }}>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            現在ハマっていることはありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media" style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/nakagawa.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification"><strong className="has-text-marker">カメラ、ドラム</strong>です！</div>
            </div>
          </div>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            大学生の間にやりたいこと、目標はありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media" style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/nakagawa.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification"><strong className="has-text-marker">芸能関係のお仕事を全力で頑張りたい</strong>と思っています。</div>
            </div>
          </div>

          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            バッグの中からお気に入りのアイテムを教えて下さい。
          </h1>
          <h2 className="subtitle"></h2>
          <figure className="image">
            <img src="/img/interview/bag/nakagawa.jpg" />
          </figure>
          <p className="subtitle" style={{ marginTop: '1rem' }}>
            「PATRICK COX」のお財布
          </p>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/nakagawa.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                物持ちがよく、<strong className="has-text-marker">お財布は高校生から、ポーチは中学生から使ってます。</strong>
                ノートも入るシンプルなバッグはどんな格好にも合うからお気に入りです。
              </div>
            </div>
          </div>
          <div className="columns" style={{ marginTop: '1rem' }}>
            <div className="column">
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <div className="content" style={{ overflow: 'hidden' }}>
                      <p>
                        <small>2019年グランプリ</small>
                        <br />
                        <strong>中川紅葉 (Nakagawa Kureha)</strong>
                        <br />
                        <small>'00.09.01 東京都出身</small>
                        <br />
                        <small>青山学院大学 文学部</small>
                        <br />
                        現在雑誌「Ray」専属読者モデルとして活躍中。
                      </p>
                      <div className="buttons is-right">
                        <Link className="button is-small" href="/interview/nakagawa-kureha">
                          特別インタビュー記事を見る
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const InterviewBagOfSaitoApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`【2019年フレキャン】準グランプリ斉藤里奈さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/bag/saito-hero.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/bag/saito-rina`} property="og:url" />
      </Helmet>
      <ArticleHeroSection src="/img/interview/bag/saito-hero.jpg" />

      <section className="section" style={{ paddingTop: '2rem', paddingBottom: 0 }}>
        <div className="container" style={{ maxWidth: 640 }}>
          <div className="tags" style={{ marginBottom: 0 }}>
            <p className="tag is-primary has-text-weight-bold">Special Contents</p>
          </div>
          <h1 className="title" style={{ lineHeight: 1.5 }}>
            【2019年フレキャン】
            <br />
            準グランプリ斉藤里奈さんに聞いてみた！バッグの中身も大公開👜 👀
          </h1>
          <div className="notification">
            2019年フレキャンの斉藤里奈さんに突撃インタビュー🎤
            <br />
            現在ハマっていることや、大学生活の目標について伺いました。
            さらにバッグの中からお気に入りのアイテムも大公開！👜 ✨
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 640 }}>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            現在ハマっていることはありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media" style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/saito.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">まだスタートしたばかりですが<strong className="has-text-marker">料理やお菓子作りです！</strong>体型維持のために糖質カットの料理を作るようにしました。夜までバイトしたあとでも作るほどはまっています！</div>
            </div>
          </div>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            大学生の間にやりたいこと、目標はありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media" style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/saito.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification"><strong className="has-text-marker">色んな場所に行き、色んな景色を見たいです！</strong>旅行が好きなので国内海外問わず行ったことのない土地に行って様々な経験を積みたいです。海外に行って異文化に触れることで知識やものの見方を広げて将来に活かしたいと思います。</div>
            </div>
          </div>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            バッグの中からお気に入りのアイテムを教えて下さい。
          </h1>
          <h2 className="subtitle"></h2>
          <figure className="image">
            <img src="/img/interview/bag/saito.jpg" />
          </figure>
          <p className="subtitle" style={{ marginTop: '1rem' }}>
            「JILL STUART」のアイシャドウ
          </p>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/saito.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">趣味はコスメ集め。<strong className="has-text-marker">特に「JILL STUART」のコスメがお気に入り！</strong></div>
            </div>
          </div>
          <div className="columns" style={{ marginTop: '1rem' }}>
            <div className="column">
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <div className="content" style={{ overflow: 'hidden' }}>
                      <p>
                        <small>2019年準グランプリ</small>
                        <br />
                        <strong>斉藤里奈 (Saito Rina)</strong>
                        <br />
                        <small>'01.01.01 埼玉県出身</small>
                        <br />
                        <small>中央大学 文学部</small>
                        <br />
                        TVのレポーター経験もあり、将来アナウンサーを目指す。
                      </p>
                      <div className="buttons is-right">
                        <Link className="button is-small" href="/interview/saito-rina">
                          特別インタビュー記事を見る
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const InterviewBagOfMochizukiApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`【2019年フレキャン】準グランプリ望月優夢さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/bag/mochizuki-hero.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/bag/mochizuki-youmu`} property="og:url" />
      </Helmet>
      <ArticleHeroSection src="/img/interview/bag/mochizuki-hero.jpg" />

      <section className="section" style={{ paddingTop: '2rem', paddingBottom: 0 }}>
        <div className="container" style={{ maxWidth: 640 }}>
          <div className="tags" style={{ marginBottom: 0 }}>
            <p className="tag is-primary has-text-weight-bold">Special Contents</p>
          </div>
          <h1 className="title" style={{ lineHeight: 1.5 }}>
            【2019年フレキャン】
            <br />
            準グランプリ望月優夢さんに聞いてみた！バッグの中身も大公開👜 👀
          </h1>
          <div className="notification">
            2019年フレキャンの望月優夢さんに突撃インタビュー🎤
            <br />
            現在ハマっていることや、大学生活の目標について伺いました。
            さらにバッグの中からお気に入りのアイテムも大公開！👜 ✨
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 640 }}>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            現在ハマっていることはありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media"  style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/mochizuki.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                <strong className="has-text-marker">メイクです。</strong>フレキャン期間に人前に出ることが増えたので、メイクがうまくなったと思います。エントリー時と今では、顔が全然違います。笑<br />
                <strong className="has-text-marker">メイクすること自体が楽しくなって、褒めていただける機会も増えてとても嬉しいです！！</strong>今はいろんなコスメを買い集めたり、美容にも気を使うようになりました。
              </div>
            </div>
          </div>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            大学生の間にやりたいこと、目標はありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media"  style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/mochizuki.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                やりたいことが2つあります。<br />
                1つ目は、大学入ってすぐに立ち上げた、<strong className="has-text-marker">アイドルダンスコピーサークルの活動</strong>です。女子大生アイドルダンスコピーサークル１番を決める大会があるので、将来全国1位になりたいと思っています。<br />
                2つ目は、読者モデルや学生キャスターなど、<strong className="has-text-marker">メディアに出る仕事</strong>をしたいと思っています。小学生からずっとメディアに携わる仕事がしたいと思っていました。<strong className="has-text-marker">フレキャンを通して、様々なお仕事をさせていただいたので、その経験やチャンスを生かしたい</strong>と思っています。
              </div>
            </div>
          </div>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">バッグの中からお気に入りのアイテムを教えて下さい。</h1>
          <h2 className="subtitle"></h2>
          <figure className="image">
            <img src="/img/interview/bag/mochizuki.jpg" />
          </figure>
          <p className="subtitle" style={{ marginTop: '1rem' }}>
            「Dior」のリップマキシマイザー
          </p>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img className="is-rounded" src="/img/interview/thumb/mochizuki.jpg" />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                リピ買いしているリップは、もう3本目！<strong className="has-text-marker">匂いも良くプルプルリップが作れます！</strong>
              </div>
            </div>
          </div>
          <div className="columns" style={{ marginTop: '1rem' }}>
            <div className="column">
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <div className="content" style={{ overflow: 'hidden' }}>
                      <p>
                        <small>2019年準グランプリ</small>
                        <br />
                        <strong>望月優夢 (Mochizuki Youmu)</strong>
                        <br />
                        <small>'00.07.02 東京都出身</small>
                        <br />
                        <small>早稲田大学 文化構想学部</small>
                        <br />
                        14年続けているダンスを生かし、ダンスサークルを自ら立ち上げる。
                      </p>
                      <div className="buttons is-right">
                        <Link className="button is-small" href="/interview/mochizuki-youmu">
                          特別インタビュー記事を見る
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
