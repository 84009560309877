import React from 'react';
import { mount, compose, withView } from 'navi';
import InterviewApp from './InterviewApp';
import MochizukiYoumu from './mochizuki-youmu';
import SaitoRina from './saito-rina';
import NakagawaKureha from './nakagawa-kureha';
import Bag from './bag';
import { View } from 'react-navi';

export default compose(
  withView(() => (
    <InterviewApp>
      <View />
    </InterviewApp>
  )),
  mount({
    '/mochizuki-youmu': MochizukiYoumu,
    '/saito-rina': SaitoRina,
    '/nakagawa-kureha': NakagawaKureha,
    '/bag': Bag,
  }),
);
