import React, {useState, useEffect} from 'react';
import Swiper from 'react-id-swiper';

interface InterviewBoxesProps {
    children: any
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function InterviewBoxes(props: InterviewBoxesProps) {
    let [loading, setLoading] = useState(false);
    useEffect(() => {
        const f = async () => {
            setLoading(true);
            await delay(100);
            setLoading(false);
        };
        f();
    }, []);
    if (loading) {
        return null;
    }
    return (
        <Swiper {...{ slidesPerView: 'auto', spaceBetween: 18, freeMode: true }}>
            {props.children}
        </Swiper>
    )
}