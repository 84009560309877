import React from 'react';

interface MediaBoxProps {
  href: string;
  src: string;
  position?: string;
  title: string;
  media: string;
  datetext: string;
  icon: string;
}

export function MediaBox(props: MediaBoxProps) {
  return (
    <div className="box as-media-box">
      <a href={props.href}>
        <article className="media">
          <div className="media-content">
            <figure className="image" style={{backgroundImage: `url(${props.src})`, backgroundPosition: props.position}} />
          </div>
        </article>
        <article className="media">
          <div className="media-left">
            <figure className="image is-32x32">
              <img src={props.icon} alt={props.media} />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <p>
                <strong>{props.title}</strong>
                <small>{props.media}</small> <small>{props.datetext}</small>
              </p>
            </div>
          </div>
        </article>
      </a>
    </div>
  );
}
