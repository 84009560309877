import React from 'react';
import { SectionTitle } from '@2020-contest-web/components/src/components';

export function ScheduleSection() {
  return (
    <section id="schedule" className="section has-background-white-bis">
      <div className="container" style={{ maxWidth: 750 }}>
        <SectionTitle
          className="title"
          style={{ marginBottom: 20 }}
          title={'SCHEDULE'}
          subtitle={<>日本一の新入生が決まるまで</>}
        />

        {/* 一次審査 */}
        <p className="title is-size-6 has-text-centered is-margin-top-2">一次審査</p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box is-danger">
              <div className="box-header">
                <p>Aブロック</p>
              </div>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">エントリー期間</p>
                  <p className="has-text-grey">2020/03/03 - 04/30</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/05/08</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box as-schedule-item-box is-warning">
              <div className="box-header">
                <p>Bブロック</p>
              </div>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-flex is-margin-bottom-5px">
                    <span>エントリー期間</span>
                  </p>
                  <p className="has-text-grey">2020/05/08 - 05/31</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/06/08</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box as-schedule-item-box is-success">
              <div className="box-header">
                <p>Cブロック</p>
              </div>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-flex is-margin-bottom-5px">
                    <span>エントリー期間</span>
                  </p>
                  <p className="has-text-grey">2020/06/02 - 06/14</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/06/18</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* 二次審査 */}
        <p className="title is-size-6 has-text-centered">二次審査</p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box is-danger">
              <div className="box-header">
                <p>Aブロック</p>
              </div>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">SHOWROOM スタートダッシュイベント</p>
                  <p className="has-text-grey">2020/05/18 12:00 - 05/23 21:59</p>
                </div>
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">二次審査期間</p>
                  <p className="has-text-grey">WEB投票 / SHOWROOM</p>
                  <p className="has-text-grey">2020/05/25 12:00 - 05/31 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/06/02</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box as-schedule-item-box is-warning">
              <div className="box-header">
                <p>Bブロック</p>
              </div>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">SHOWROOM スタートダッシュイベント</p>
                  <p className="has-text-grey">2020/06/11 12:00 - 06/16 21:59</p>
                </div>
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">二次審査期間</p>
                  <p className="has-text-grey">WEB投票 / SHOWROOM</p>
                  <p className="has-text-grey">2020/06/18 12:00 - 06/24 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/06/26</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box as-schedule-item-box is-success">
              <div className="box-header">
                <p>Cブロック</p>
              </div>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">SHOWROOM スタートダッシュイベント</p>
                  <p className="has-text-grey">2020/06/22 12:00 - 06/27 21:59</p>
                </div>
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">二次審査期間</p>
                  <p className="has-text-grey">WEB投票 / SHOWROOM</p>
                  <p className="has-text-grey">2020/06/29 12:00 - 07/05 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/07/07</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* 三次審査 */}
        <p className="title is-size-6 has-text-centered"><span>三次審査</span></p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box" style={{ padding: '1.25rem' }}>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">三次審査期間</p>
                  <p className="has-text-grey">WEB投票 / mysta</p>
                  <p className="has-text-grey">2020/08/24 12:00 - 8/30 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/09/01</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* 四次審査 */}
        <p className="title is-size-6 has-text-centered"><span>四次審査</span></p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box" style={{ padding: '1.25rem' }}>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">四次審査期間</p>
                  <p className="has-text-grey">WEB投票</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/09/06 12:00 - 09/19 21:59</p>
                  <p className="has-text-grey">SHOWROOM</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/09/06 12:00 - 09/12 21:59</p>
                  <p className="has-text-grey">mysta</p>
                  <p className="has-text-grey">2020/09/13 12:00 - 09/19 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/09/22</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        
        {/* クォーターファイナル審査 */}
        <p className="title is-size-6 has-text-centered"><span>クォーターファイナル審査</span><span className="tag is-size-7 is-primary is-margin-left-0-5 is-hidden">開催中</span></p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box" style={{ padding: '1.25rem' }}>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">クォーターファイナル審査期間</p>
                  <p className="has-text-grey">WEB投票</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/10/05 12:00 - 10/11 21:59</p>
                  <p className="has-text-grey">SHOWROOM</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/10/05 12:00 - 10/11 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/10/13</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        {/* セミファイナル審査 */}
        <p className="title is-size-6 has-text-centered"><span>セミファイナル審査</span></p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box" style={{ padding: '1.25rem' }}>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">セミファイナル審査期間</p>
                  <p className="has-text-grey">WEB投票</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/10/25 12:00 - 11/01 23:59</p>
                  <p className="has-text-grey">特別協賛審査</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/10/25 12:00 - 10/31 23:59</p>
                  <p className="has-text-grey">mysta</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/10/25 12:00 - 10/31 23:59</p>
                  <p className="has-text-grey">SHOWROOM</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/10/26 12:00 - 11/01 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">通過者発表</p>
                  <p className="has-text-grey">2020/11/05</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        {/* セミファイナル審査 */}
        <p className="title is-size-6 has-text-centered"><span>ファイナル審査</span></p>
        <div className="columns is-margin-bottom-2">
          <div className="column">
            <div className="box as-schedule-item-box" style={{ padding: '1.25rem' }}>
              <div className="box-body">
                <div className="is-margin-bottom-1">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">ファイナル審査期間</p>
                  <p className="has-text-grey">WEB投票</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/11/22 12:00 - 11/29 21:59</p>
                  <p className="has-text-grey">特別協賛審査</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/11/22 12:00 - 11/28 23:59</p>
                  <p className="has-text-grey">mysta</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/11/22 12:00 - 11/28 23:59</p>
                  <p className="has-text-grey">SHOWROOM</p>
                  <p className="has-text-grey is-margin-bottom-5px">2020/11/23 12:00 - 11/29 21:59</p>
                </div>
                <div className="">
                  <p className="has-text-dark has-text-weight-bold is-margin-bottom-5px">表彰式</p>
                  <p className="has-text-grey">2020/12/06</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <p className="title is-size-6 has-text-centered">・・・</p>
        <p className="is-size-7 has-text-grey has-text-centered is-margin-bottom-2">
          スケジュールは予告なく変更されることがあります。
        </p>
      </div>
    </section>
  );
}
