import React from 'react';

interface NewsProps {
    json: any;
}

const News: React.FC<NewsProps> = props => (
    <div className="card as-news">
        <div className="card-content">
            <ul>
                {props.json.map((item: { time: string; content_html: string }, index: number) => {
                    return (
                        <li key={index}>
                            <time>{item.time}</time>
                            <p dangerouslySetInnerHTML={{ __html: item.content_html }}></p>
                        </li>
                    );
                })}
            </ul>
        </div>
    </div>
);

export default News;