import React from 'react';
import classNames from 'classnames';
import { LazyImageFull, ImageState } from 'react-lazy-images';
import styled from 'styled-components';
import {useInView} from "react-hook-inview";

const Img = styled.img`
    opacity: 0;
    transition: opacity 0.5s;
    &.is-load-success&.is-inview {
      opacity: 1;
    }
`;

export default function LazyImage(props: React.ImgHTMLAttributes<HTMLImageElement>) {
    const [ref, inView] = useInView({unobserveOnEnter: true});

    return <figure className={classNames("image", props.className)} ref={ref}>
        <LazyImageFull observerProps={{threshold: 0.01, rootMargin: "540px 0px"}} src={props.src ?? ""} alt={props.alt}>
            {({ imageProps, imageState, ref }) => {
                if (imageState === ImageState.NotAsked) {
                    return <div ref={ref} />;
                }
                return (
                    <Img
                        {...imageProps}
                        className={classNames({
                            'is-load-success': imageState === ImageState.LoadSuccess,
                            'is-inview': inView
                        })}
                        ref={ref}
                    />
                );
            }}
        </LazyImageFull>
    </figure>
}