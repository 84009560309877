import React from 'react';
import {Link} from "react-navi";

interface InterviewBoxProps {
  src: string;
  message: string;
  href: string
  name: string;
  award: "2019グランプリ" | "2019準グランプリ"
}

export function InterviewBox(props: InterviewBoxProps) {
  return (
    <div className="box as-interview-box">
      <Link href={props.href}>
        <article className="media">
          <div className="media-content">
            <figure className="image">
              <img src={props.src} alt="Hero" />
            </figure>
            <div className="content">
              <p className="tags has-addons">
                <span className="tag has-text-weight-bold">{props.award}</span>
                <span className="tag is-primary has-text-weight-bold">{props.name}</span>
              </p>
              <p className="has-text-weight-bold">{props.message}</p>
            </div>
            <nav className="buttons is-centered are-small">
              <a className="button is-text is-fullwidth">記事を読む</a>
            </nav>
          </div>
        </article>
      </Link>
    </div>
  );
}
