import React from 'react';
import { mount, compose, route, redirect } from 'navi';
import { EntryListApp, EntryListAppProps } from './EntryListApp';

const EntriesRoute = (props: EntryListAppProps) => {
  return route({
      title: `${props.title} | ${process.env.REACT_APP_CONTEST_TITLE}`,
      getView: () => <EntryListApp {...props} />,
  });
};

export default compose(
  mount({
    '/': redirect('/entries/thirds'),
    // NOTE:-------------
    //      [二次審査]
    // ------------------
    '/seconds': EntriesRoute({ title: '二次審査', subtitle: '進出者一覧', stage: '1' }),
    '/seconds/a': redirect('/entries/seconds'),
    '/seconds/b': redirect('/entries/seconds'),
    '/seconds/c': redirect('/entries/seconds'),
    '/1': redirect('/entries/seconds'),
    '/1/a': redirect('/entries/seconds'),
    '/1/b': redirect('/entries/seconds'),
    '/1/c': redirect('/entries/seconds'),
    // NOTE:-------------
    //      [三次審査]
    // ------------------
    '/thirds': EntriesRoute({ title: '三次審査', subtitle: '進出者一覧', stage: '2' }),
    '/thirds/a': redirect('/entries/thirds'),
    '/thirds/b': redirect('/entries/thirds'),
    '/thirds/c': redirect('/entries/thirds'),
    '/2': redirect('/entries/thirds'),
    '/2/a': redirect('/entries/thirds'),
    '/2/b': redirect('/entries/thirds'),
    '/2/c': redirect('/entries/thirds'),
    // NOTE:-------------
    //      [四次審査]
    // ------------------
    '/fourths': EntriesRoute({ title: '四次審査', subtitle: '進出者一覧', stage: '3' }),
    '3': redirect('/entries/fourths'),
    // NOTE:-------------
    //      [クォーターファイナル審査]
    // ------------------
    '/quarterfinal': EntriesRoute({ title: 'クォーターファイナル審査', subtitle: '進出者一覧', stage: '4' }),
    '4': redirect('/entries/quarterfinal'),
    // NOTE:-------------
    //      [セミファイナル審査]
    // ------------------
    '/semifinal': EntriesRoute({ title: 'セミファイナル審査', subtitle: '進出者一覧', stage: '5' }),
    '5': redirect('/entries/semifinal'),
    // NOTE:-------------
    //      [ファイナル審査]
    // ------------------
    '/final': EntriesRoute({ title: 'ファイナル審査', subtitle: '進出者一覧', stage: '6' }),
    '6': redirect('/entries/final'),
  }),
);
