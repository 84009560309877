import React, { useEffect } from 'react';
import { useInView } from 'react-hook-inview';

interface TwitterProps {}
export const Twitter: React.FC<TwitterProps> = props => {
    const [ref, isVisible] = useInView({
        threshold: 1,
        unobserveOnEnter: true,
    });
    useEffect(() => {
        if ((window as any).twttr) {
            (window as any).twttr.widgets.load();
        }
    }, [isVisible]);
    return (
        <div className="card as-twitter">
            <div className="card-content" ref={ref}>
                {isVisible && (
                    <a
                        className="twitter-timeline"
                        data-chrome="noheader nofooter noborders"
                        data-lang="ja"
                        href="https://twitter.com/MISSMRCOLLE?ref_src=twsrc%5Etfw"
                    >
                        Tweets by MISSMRCOLLE
                    </a>
                )}
            </div>
        </div>
    );
};
