import React, { useEffect, useState } from 'react';
import { isMobile } from 'is-mobile';
import { Entry } from '../../types';

interface VoteButtonProps {}
export const VoteButton: React.FC<VoteButtonProps> = () => {
  return (
    <div className="has-text-centered is-margin-bottom-1">
      <a className="button is-fullwidth is-gorgeous is-medium" href={process.env.REACT_APP_VOTE_URL}>
        <span>WEB投票サイト</span>
      </a>
    </div>
  );
};
