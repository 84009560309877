import React from 'react';
import { useCurrentRoute, useView } from 'react-navi';
import { Helmet } from 'react-navi-helmet-async';
import { SectionTitle, Footer, Header, PostBreadCrumb } from '@2020-contest-web/components/src/components';

function PostApp() {
  let { title, data, url } = useCurrentRoute();
  let { content } = useView()!;
  let { tags } = data;
  let { MDXComponent } = content;
  return (
    <div id="PostApp">
      <Helmet>
        <meta content={`${title} | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title" />
        <meta content={`${process.env.REACT_APP_URL}/${url.href}`} property="og:url" />
        <title>{`${title} | ${process.env.REACT_APP_WEBSITE_NAME}`}</title>
      </Helmet>
      <Header />
      <section className="section">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 30 }} title={'NEWS'} subtitle={'お知らせ'} />
        </div>
      </section>

      <section className="section as-profile">
        <div className="container">
          <PostBreadCrumb title={title} />
          <div className="content">
            <h3>{title}</h3>
            <div className="tags">
              {tags.map((tag: string) => (
                <span className="tag" key={tag}>
                  {tag}
                </span>
              ))}
            </div>
            <MDXComponent />
          </div>
        </div>
      </section>
      <Footer href="mailto:frecam@age.co.jp" />
    </div>
  );
}

export default PostApp;
