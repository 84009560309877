import React from 'react';
const logo = require('./logo.png');

export function MystaButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
        <a {...props} className="button is-mysta is-white">
            <figure className="image">
                <img src={logo} />
            </figure>
        </a>
    );
}

export function MystaImage() {
    return (
        <figure className="image" style={{display: "inline-block"}}>
            <img src={logo} style={{maxWidth: 120}} />
        </figure>
    );
}