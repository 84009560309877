import React from 'react';
const logo = require('./logo.png');
const banner = require('./banner.jpg');

export function ShowroomButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <a {...props} className="button is-showroom is-white">
        <figure className="image">
            <img src={logo} />
        </figure>
    </a>
  );
}

export function ShowroomImage() {
  return (
    <figure className="image" style={{display: "inline-block"}}>
      <img src={banner} style={{maxWidth: 160}} />
    </figure>
  );
}
