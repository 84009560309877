import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';

export function Header(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <nav className={classNames('navbar as-header', props.className)}>
      <div className="container is-flex">
        <div className="navbar-brand">
          <Link className="navbar-item" href="/">
            <img src={'/img/logo_dark_yoko.svg'} alt={process.env.REACT_APP_WEBSITE_NAME} />
          </Link>
        </div>
        <div className="navbar-end">
          <div className="navbar-item buttons">
          </div>
        </div>
      </div>
    </nav>
  );
}
