import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';
import history from '../../json/history.json';
import News from '@2020-contest-web/components/src/components/News';
import news from '../../json/news.json';
import {
  OnLiveLink,
  ShowroomImage,
  SisterContestSection,
  ChanceSection,
  HistoryTable,
  InterviewBoxes,
  InterviewBox,
  InterviewSection,
  SectionTitle,
  Twitter,
  Entries,
  Onlives,
  Footer,
  ContestFaq,
  EntryCard,
  ShowroomEventBox,
  MystaImage,
  Photo,
} from '@2020-contest-web/components/src/components/';
import { Entry, Onlive } from '@2020-contest-web/components/src/types/';
import { ScheduleSection } from '../ScheduleSection';
import { MediaSection } from '../MediaSection';
import { useResource } from 'retil';
import { entryList, onliveList } from '../../resources';

export function LandingSections() {
  const [state] = useResource(entryList);
  const [onlives] = useResource(onliveList);
  return (
    <>
      <section id="award" className="section has-background-gold-stripe" style={{ paddingTop: 150 }}>
        <div className="container" style={{ maxWidth: 740 }}>
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Award'} subtitle={'受賞者一覧'} />
          {/* グランプリ */}
          <div className="has-text-centered is-margin-bottom-4">
            <h1 className="is-size-3 has-text-weight-bold is-margin-bottom-2">グランプリ</h1>
            <div className="columns is-centered is-mobile">
              <div className="column is-full" style={{ maxWidth: 400 }}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/500">
                    <Photo
                      className={classNames('is-solided')}
                      src={'https://hauptmann.age.co.jp/contests/2963717b9ba7518887c0368fed9dba603b71c693.jpg'}
                      alt={'石川真衣'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'石川真衣'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'立教大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="has-text-centered is-margin-bottom-4">
            <h1 className="is-size-4 is-margin-bottom-2 has-text-weight-bold">準グランプリ</h1>
            <div className="columns is-centered is-mobile">
              <div className="column is-half" style={{maxWidth: 320}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/217">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/40f58d9825daee773fba106a6eb530f33a1dfaa6.jpg'}
                        alt={'長谷川新奈'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'長谷川新奈'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'明治学院大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 320}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/259">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/da78aea8172fc4291434223f79e914fcfd27b2b4.jpg'}
                        alt={'高岡奈々葉'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'高岡奈々葉'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'愛媛大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <h1 className="is-size-4 is-margin-bottom-2 has-text-weight-bold">審査員特別賞</h1>
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/335">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/582c9d2181ba13ae2e45563704a8e9c4405ee173.jpg'}
                        alt={'川岸瑠那'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'川岸瑠那'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'東洋大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/342">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/df860347198f42298e9228dd28ed181cce923582.jpg'}
                        alt={'加藤愛梨'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'加藤愛梨'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'中央大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/364">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/1cfb6585448435438fad7f7cfba90dc75722a806.jpg'}
                        alt={'金山喜乃花'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'金山喜乃花'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'早稲田大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* リゼクリニック賞・リゼウォーク賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  リゼクリニック賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/217">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/40f58d9825daee773fba106a6eb530f33a1dfaa6.jpg'}
                        alt={'長谷川新奈'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'長谷川新奈'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'明治学院大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  リゼウォーク賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/342">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/df860347198f42298e9228dd28ed181cce923582.jpg'}
                        alt={'加藤愛梨'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'加藤愛梨'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'中央大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* テレビ朝日アスク賞・modelpress賞・Now me. by RUNWAY channel 賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  テレビ朝日アスク賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/147">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/da166ef1ff587ad181fd8ad875bdeea8a5db9c54.jpg'}
                        alt={'小寺陽向'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'小寺陽向'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'東京都立大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  modelpress賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/500">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/2963717b9ba7518887c0368fed9dba603b71c693.jpg'}
                        alt={'石川真衣'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'石川真衣'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'立教大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  Now me. <span className="is-size-7">by RUNWAY channel</span> 賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/438">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/442ac16128d6e78fcffd51ae10cc039d3608b165.jpg'}
                        alt={'南野巴那'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'南野巴那'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'洗足学園音楽大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* SHOWROOM賞・B.L.T.賞・mysta賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  SHOWROOM賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/217">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/40f58d9825daee773fba106a6eb530f33a1dfaa6.jpg'}
                        alt={'長谷川新奈'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'長谷川新奈'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'明治学院大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  B.L.T.賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/500">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/2963717b9ba7518887c0368fed9dba603b71c693.jpg'}
                        alt={'石川真衣'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'石川真衣'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'立教大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  mysta賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/189">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/fff6e139943a982d6131fc570d834b19781afb1d.jpg'}
                        alt={'荻原志帆'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'荻原志帆'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'共立女子大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* RISM賞・HALIFT賞・ZUQUUUN賞・MASCODE賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  RISM賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/317">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/364a7d53035c69cd542c22e0c204a5b07ea6c806.jpg'}
                        alt={'伊藤美波'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'伊藤美波'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'早稲田大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  HALIFT賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/106">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/a32cae51663462bf88ea77f0570ce3fc7dd0e42b.jpg'}
                        alt={'絢瀬まる'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'絢瀬まる'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'上智大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  ZUQUUUN賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/309">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/44369ab5a92180e68293a0ab42fdb6aa5f9d1a4a.jpg'}
                        alt={'一ノ瀬舞夕'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'一ノ瀬舞夕'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'学習院大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  MASCODE賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/785">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/5e0dcdcdf2e689b944cacc96a0e7e7f0b0fd9609.jpg'}
                        alt={'鈴木亜優'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'鈴木亜優'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'群馬大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* MISS COLLE賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  MISS COLLE賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/438">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/442ac16128d6e78fcffd51ae10cc039d3608b165.jpg'}
                        alt={'南野巴那'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'南野巴那'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'洗足学園音楽大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 740 }}>
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Event'} subtitle={'表彰式'} />
          <table className="table is-fullwidth as-information">
            <tbody>
              <tr>
                <th className="is-narrow">日時</th>
                <td>
                  2020年12月06日(日) 開演 12:30
                  <br />
                  <span className="is-size-7">※ 無観客にて開催いたします。</span>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">会場</th>
                <td>
                  <a className="has-text-grey-dark" href="https://www.ex-theater.com/" target="_blank">
                    EX THEATER ROPPONGI
                  </a>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">ライブ配信</th>
                <td>SHOWROOM、17LIVEにて無料配信</td>
              </tr>
              <tr>
                <th className="is-narrow">配信URL</th>
                <td>
                  <a href="https://www.showroom-live.com/frecam2020" target="_blank">
                    SHOWROOM
                  </a>
                  <br />
                  <a href="https://17.live/profile/r/13520481" target="_blank">
                    17LIVE
                  </a>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">MC</th>
                <td>
                  <span className="has-text-marker">トレンディエンジェル</span>
                  <br />
                  <br />
                  <img src="/img/mc.jpg" />
                </td>
              </tr>
              <tr>
                <th className="is-narrow">GUEST</th>
                <td>
                  <span className="has-text-marker">ゆきぽよ</span>
                  <br />
                  <br />
                  <img src="/img/guest.jpg" width="320" />
                </td>
              </tr>
              <tr>
                <th className="is-narrow">PRESENTER</th>
                <td>
                  <span className="has-text-marker">中川紅葉</span>
                  <br />
                  <span className="is-size-7-mobile is-size-6 is-margin-top-0-5">青山学院大学 2年</span>
                  <br />
                  <span className="is-size-7-mobile is-size-6 is-margin-top-0-5">
                    FRESH CAMPUS CONTEST 2019 <br className="is-hidden-tablet" />
                    グランプリ
                  </span>
                  <br />
                  <br />
                  <img src="https://2019.frecam.jp/images/entries/425.jpg" width="320" />
                  <br />
                  <hr />
                  <span className="has-text-marker">斉藤里奈</span>
                  <br />
                  <span className="is-size-7-mobile is-size-6 is-margin-top-0-5">中央大学 2年</span>
                  <br />
                  <span className="is-size-7-mobile is-size-6 is-margin-top-0-5">
                    FRESH CAMPUS CONTEST 2019 <br className="is-hidden-tablet" />
                    準グランプリ
                  </span>
                  <br />
                  <br />
                  <img src="https://2019.frecam.jp/images/entries/73.jpg" width="320" />
                  <br />
                  <hr />
                  <span className="has-text-marker">望月優夢</span>
                  <br />
                  <span className="is-size-7-mobile is-size-6 is-margin-top-0-5">早稲田大学 2年</span>
                  <br />
                  <span className="is-size-7-mobile is-size-6 is-margin-top-0-5">
                    FRESH CAMPUS CONTEST 2019 <br className="is-hidden-tablet" />
                    準グランプリ
                  </span>
                  <br />
                  <br />
                  <img src="https://2019.frecam.jp/images/entries/6.jpg" width="320" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Prize'} subtitle={'グランプリ特典'} />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile">
              <figure className="image" style={{ maxWidth: 480, borderRadius: 10, overflow: 'hidden' }}>
                <img src="/img/prize.jpg" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'SPECIAL SPONSOR'}
            subtitle={'特別協賛'}
          />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile">
              <figure className="image has-text-centered">
                <img src="/img/partner/rize.svg" style={{ display: 'inline-block', maxHeight: 100, width: 'auto' }} />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'SPECIAL PARTNER'}
            subtitle={'特別協力'}
          />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img
                  src="/img/partner/tvasahiask.png"
                  style={{ display: 'inline-block', maxHeight: 40, width: 'auto' }}
                />
              </figure>
            </div>
            <div className="column is-narrow is-padding-bottom-2-mobile">
              <figure className="image has-text-centered">
                <img src="/img/partner/jj.jpg" style={{ display: 'inline-block', maxHeight: 40, width: 'auto' }} />
              </figure>
            </div>
            <div className="column is-narrow is-margin-0-auto-mobile is-padding-bottom-2-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img
                  src="/img/partner/modelpress.png"
                  style={{ display: 'inline-block', maxHeight: 37, width: 'auto' }}
                />
              </figure>
            </div>
            <div className="column is-narrow is-margin-0-auto-mobile">
              <figure className="image has-text-centered">
                <img src="/img/partner/ray.svg" style={{ display: 'inline-block', maxHeight: 48, width: 'auto' }} />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section id="partner" className="section">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'PARTNER'} subtitle={'協賛'} />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2 is-padding-bottom-0-mobile">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img src="/img/partner/dazzlin.svg" style={{ display: 'inline-block', maxHeight: 30, width: 'auto' }} />
              </figure>
            </div>
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 320 }}>
              <figure className="image has-text-centered">
                <img
                  src="/img/partner/mercuryduo.svg"
                  style={{ display: 'inline-block', maxHeight: 30, width: 'auto' }}
                />
              </figure>
            </div>
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img src="/img/partner/murua.svg" style={{ display: 'inline-block', maxHeight: 30, width: 'auto' }} />
              </figure>
            </div>
          </div>
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img src="/img/partner/resexxy.svg" style={{ display: 'inline-block', maxHeight: 30, width: 'auto' }} />
              </figure>
            </div>
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img src="/img/partner/mysta.png" style={{ display: 'inline-block', maxHeight: 30, width: 'auto' }} />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* MYSTA
      <section id="mysta" className="section is-mysta is-paddingless-left-mobile is-paddingless-right-mobile">
        <div className="container" style={{ maxWidth: 920 }}>
          <SectionTitle
              className="title"
              title={<MystaImage />}
              style={{ marginBottom: 30 }}
              subtitle={'動画配信'}
          />
        </div>
      </section>
       */}
      <section id="showroom" className="section is-showroom is-paddingless-left-mobile is-paddingless-right-mobile">
        <div className="container" style={{ maxWidth: 920 }}>
          <SectionTitle
            className="title"
            title={<ShowroomImage />}
            style={{ marginBottom: 30 }}
            subtitle={'ライブ配信'}
          />
          <h2 className="title is-size-5" style={{ marginLeft: 18, color: 'rgb(238, 238, 238)' }}>
            配信中
          </h2>
          <div className="is-margin-bottom-2">
            <Onlives state={onlives}>
              {(list: Onlive[]) =>
                list.map((item: Onlive, index: number) => (
                  <div key={index} style={{ maxWidth: 200 }}>
                    <OnLiveLink item={item} />
                  </div>
                ))
              }
            </Onlives>
          </div>
        </div>
      </section>

      {/* ENTRY */}
      <section className="section">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'Entries'}
            subtitle={'ファイナル審査進出者'}
          />
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={state} size={4} stage={'6'}>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                      'is-hidden-tablet-only': index === 3,
                    })}
                    key={item.entry_id}
                  >
                    <EntryCard onlive={isOnlive(item)} entry={item} href={`/profiles/${item.entry_id}`} />
                  </div>
                ))
              }
            </Entries>
          </div>
          <div className="has-text-centered">
            <Link className="button is-rounded is-medium is-frecam" href="/entries/final">
              <span>もっと見る</span>
            </Link>
          </div>
        </div>
      </section>
      <ChanceSection />
      <MediaSection />
      <InterviewSection
        color="#f5f5f5"
        profileNode={
          <>
            <div style={{ marginBottom: 10 }}>
              <p>2019年グランプリ</p>
              <p>
                <span className="has-text-weight-bold is-size-5">中川紅葉</span> (Nakagawa Kureha)
              </p>
            </div>
            <p>
              ’00.09.01 東京都出身
              <br />
              青山学院大学 文学部
              <br />
              趣味 写真/ドラム 現在雑誌「Ray」専属読者モデルとして活躍中。
            </p>
          </>
        }
        titleNode={
          <h2 className="title">
            将来の夢を見つけたり、
            <br className="is-hidden-tablet" />
            叶えるために
            <br />
            フレキャン2020に参加しませんか？‬
          </h2>
        }
        textNode={
          <>
            <p>
              ‪"日本一の新入生を決めるミスコン"を通して、
              <br />
              一緒に戦う｢ライバル兼友達｣ができました。
            </p>
            <p>
              フレキャンに参加する中で、
              <br />
              夢を見つけるきっかけを作って欲しいです‬。
            </p>
            <p>
              ‪‪新入生である1年間は、
              <br />
              二度と戻らない大きな挑戦の年だと思います‬。
            </p>
            <p>是非沢山のエントリーお待ちしています。</p>
          </>
        }
      />
      <section className="section has-background-white" style={{ padding: '3rem 0 4rem', overflow: 'hidden' }}>
        <div className="container" style={{ maxWidth: 822 }}>
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'INTERVIEW'}
            subtitle={'2019受賞者のインタビュー'}
          />
          <InterviewBoxes>
            <div style={{ maxWidth: 250 }}>
              <InterviewBox
                name="斉藤 里奈"
                award="2019準グランプリ"
                href="/interview/saito-rina"
                src="/img/interview/saito-rina/thumbnail.jpg"
                message="フレキャンを本気で頑張ることで過去の自分より絶対に成長できると思います。"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <InterviewBox
                name="望月 優夢"
                award="2019準グランプリ"
                href="/interview/mochizuki-youmu"
                src="/img/interview/mochizuki-youmu/thumbnail.jpg"
                message="フレキャン期間の経験と、沢山のファンの方や友達、家族の応援のおかげで、何事に関しても視野が広がりました。"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <InterviewBox
                name="中川 紅葉"
                award="2019グランプリ"
                href="/interview/nakagawa-kureha"
                src="/img/interview/nakagawa-kureha/thumbnail.jpg"
                message="フレキャンに挑戦して夢へのきっかけを作って欲しいです！"
              />
            </div>
          </InterviewBoxes>
        </div>
      </section>
      <section className="section has-background-white">
        <div className="container" style={{ maxWidth: 750 }}>
          <SectionTitle className="title" style={{ marginBottom: 30 }} title={'REQUIREMENT'} subtitle={<>応募資格</>} />

          <div className="message">
            <div className="message-body content" style={{ paddingTop: '0.5rem' }}>
              <ul>
                <li>四年制大学・短期大学・大学院・専門学校等、それらに相当する学校に在籍する女性の方。</li>
                <li>2020年度入学の新一年生であること。</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <ScheduleSection />
      <section className="section has-background-white-ter">
        <div className="container" style={{ maxWidth: 750 }}>
          <SectionTitle className="title" style={{ marginBottom: 30 }} title={'FAQ'} subtitle={<>よくある質問</>} />
          <div>
            <ContestFaq
              question="エントリー時に必要な写真はどのような写真が良いですか？"
              answer={
                <>
                  半年以内に撮影した顔がはっきりと分かるお写真をご用意ください。
                  <br />
                  かしこまった写真のご提出ではなく、現在お持ちのものでも結構ですし、自撮りの写真でも問題ありません。複数人が写っている写真の場合は、ご自身のみが写るよう切り取ったものをお送りください。（※プリクラは不可）
                </>
              }
            />
            <ContestFaq
              question="どのような審査がありますか？"
              answer={
                <>
                  全国を対象としたコンテストであるため、審査はインターネット上で行なわれます。例年、WEB投票や各種SNSのを活用した審査がございます。
                </>
              }
            />
            <ContestFaq
              question="バイトしながらでも審査に影響はないですか？"
              answer={
                <>
                  問題ございません。実際、過去のグランプリや受賞者の多くはアルバイトや他の課外活動を行いながら審査に参加されていました。
                </>
              }
            />
            <ContestFaq
              question="大学や学校によって審査に有利、不利などはありますか？"
              answer={<>在籍されている学校による有利不利は一切ございません。</>}
            />
            <ContestFaq
              question="新型コロナウイルス感染症の影響で中止になることはありますか？"
              answer={
                <>
                  現時点で中止することは想定しておりません。ただし、今後の状況や情勢や関係機関の方針により、スケジュールや内容を変更させていただく可能性もございます。
                </>
              }
            />
            <ContestFaq
              question="芸能事務所に所属していても応募は可能ですか？"
              answer={<>はい可能です。ただし、所属事務所に必ず許可を得た上で応募ください。</>}
            />
          </div>
        </div>
      </section>
      <section className="section has-background-white">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 30 }}
            title={'HISTORY'}
            subtitle={<>歴代受賞者一覧</>}
          />
          <HistoryTable json={history} />
        </div>
      </section>
      <SisterContestSection themeColor="#006193" src="/img/misscircle.svg">
        <p className="has-text-weight-bold" style={{ marginBottom: 30 }}>
          日本一のサークル美女を決めるコンテスト！
        </p>
        <a href="https://2020.misscircle.jp/" className="button is-medium is-rounded is-misscircle">
          特設サイトを見る
        </a>
      </SisterContestSection>
      <section id="news" className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <SectionTitle className="title" style={{ marginBottom: 30 }} title={'News'} subtitle={<>お知らせ</>} />
              <News json={news} />
            </div>
            <div className="column">
              <SectionTitle className="title" style={{ marginBottom: 30 }} title={'FOLLOW'} subtitle={<>最新情報</>} />
              <div className="is-margin-bottom-3">
                <Twitter />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer href="mailto:frecam@age.co.jp" />
    </>
  );
  function isOnlive(entry: Entry) {
    const [onlive] = onlives.data.filter((onlive: Onlive) => onlive.entry_id == entry.entry_id);
    return !!onlive;
  }
}
