import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';
import {Entry} from '../../types';
import {Photo} from "../Photo";

interface EntryCardProps {
  entry: Entry;
  onlive: boolean;
}

export function EntryCard({ entry, onlive, className, href }: EntryCardProps & Link.Props) {
  return (
    <div className={classNames('entry-card', className)}>
      <Link href={href}>
        <Photo
            className={classNames({
              'is-onlive': onlive
            })}
           src={entry.image ?? "/img/noimage.svg"}
           alt={entry.public_name ?? ""}
        >
          <figcaption className="entry-card-mask">
            <figure className="image is-1by1">
              <svg width="240" height="240" viewBox="0 0 240 240">
                <path fill="none" d="M15,14.5 v210 h210" />
                <path fill="none" d="M225,225 v-210 h-210" />
              </svg>
              <figcaption>
                <p className="has-text-weight-bold">詳しく見る</p>
              </figcaption>
            </figure>
          </figcaption>
        </Photo>
        <div className="entry-card-title">
          <p className="entry-card-title-name">{entry.public_name ?? ""}</p>
          <p className="entry-card-title-university">{entry.university}</p>
        </div>
      </Link>
    </div>
  );
}
