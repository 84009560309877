import React from 'react';
import { mount, compose, route, redirect, withView } from 'navi';
import PostApp from "./PostApp";
import news from './news';

const fromPairs = require('lodash.frompairs');

export default compose(
  withView((req) => <PostApp />  ),
  mount(fromPairs(news.map(post => ['/' + post.slug, post.getPage]))),
);
