import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import LazyImage from '@2020-contest-web/components/src/components/LazyImage';

export default compose(
  mount({
    '/': route({
      getView: () => InterviewArticleApp,
      title: `2019年準グランプリ 望月優夢「フレキャン期間の経験と、沢山のファンの方や友達、家族の応援のおかげで、何事に関しても視野が広がりました。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`
    }),
  }),
);

const InterviewArticleApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`2019年準グランプリ 望月優夢「フレキャン期間の経験と、沢山のファンの方や友達、家族の応援のおかげで、何事に関しても視野が広がりました。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/mochizuki-youmu/ogp.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/mochizuki-youmu`} property="og:url" />
      </Helmet>
      {/* Image */}
      <section className="section as-image-section" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <div className="container">
          <figure className="image is-256by134">
            <img src="/img/interview/mochizuki-youmu/hero.jpg" />
          </figure>
        </div>
      </section>
      <div>
        {/* Question 1  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q1.</span>
                    <span>コンテストに参加したきっかけは？</span>
                  </div>
                  <div className="faq-answer">
                      <strong className="has-text-marker">フレキャン2018の永松野々花さんがグランプリを受賞されたのを「めざましテレビ」で拝見し、</strong>興味を持ったのが最初のきっかけでした。<br />
                      大学入学を控えた昨年の3月頃は、入りたいサークルや将来の夢も迷っていて、自分が本当にやりたいことは何なのだろうと悩んでいました。<br />
                      高校生活では、やりきれなかったことや後悔したと思うことが多かったので、大学ではそのような思いをしたくないと思い、 <strong className="has-text-marker">何かのきっかけになればという気持ちで、入学式の日に応募しました。</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 2  */}
        <section className="section">
          <div className="container">
            <div className="columns is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                  className="image is-1by1"
                  src="/img/interview/mochizuki-youmu/2019.jpg"
                  width={640}
                  height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q2.</span>
                    <span>参加して嬉しかったことは？</span>
                  </div>
                  <div className="faq-answer">
                      <strong className="has-text-marker">応援してくださる方が出来たこと</strong>です。<br />
                      フレキャンのファイナル審査まではもちろんですが、 <strong className="has-text-marker">フレキャンが終わった今でも、その後の活動を応援してくださる方々がいて</strong>本当に嬉しいです。<br />
                      フレキャン期間には学園祭があったのですが、遠い地域からも含めて20人近い方に足を運んでいただきました。
                      これだけ応援してくださる方々がいるのだから、どんなに辛いことがあっても、<strong className="has-text-marker">ここで負けちゃダメだな、もっともっと頑張ろうと思うことができます</strong>し、本当に励みになっています。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 3  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q3.</span>
                    <span>審査期間中に気をつけたことは？</span>
                  </div>
                  <div className="faq-answer">
                      <strong className="has-text-marker">スケジュール・時間管理</strong>です。<br />
                      例えばSHOWROOM審査では、1日の配信時間制限ある中で、1日10枠の配信を行うなど、<strong className="has-text-marker">秒単位の時間管理が身につきます(笑)。</strong><br />
                      審査の詳細が発表されたら、<strong  className="has-text-marker">すぐにスケジュール帳にびっしりと書き込むようしました。</strong><br />
                      審査期間もアルバイトやサークルを両立していたので、かなり体力的にキツかったですが、応援していただく立場である以上、<strong  className="has-text-marker">時間管理やスケジュール管理は特に徹底していました。</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 4  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q4.</span>
                    <span>準グランプリを受賞して変わったことは？</span>
                  </div>
                  <div className="faq-answer">
                      <strong className="has-text-marker">何事に関しても視野が広がりました。</strong>
                      それはフレキャン期間の経験と、沢山のファンの方や友達、家族の応援のおかげで、準グランプリという素晴らしい賞を頂くことができ、自分の自信につながったからだと思います。<br />
                      特に私の場合、三次審査までは受賞どころかファイナリストにもなれないだろうという立場から、<strong className="has-text-marker">さまざまな試行錯誤をした末の準グランプリでした。</strong><br />
                      例えば、ダンス配信と称してダンススタジオ自分で予約してダンスを踊ったり、朝昼夜問わず配信をしたりと、今となっては信じられないほど努力していたと思います。<br />
                      フレキャン期間は辛いことや、辞めようと思ったことも数えきれないくらいありましたが、沢山の応援に支えられ、<strong className="has-text-marker">無理だと思うことでも、限界を決めずに試行錯誤すれば、実現可能になると考えられるようになりました。</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Image */}
        <section className="section as-image-section">
          <LazyImage className="image" src="/img/interview/mochizuki-youmu/divider.jpg" />
        </section>
        {/*  Question 5  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q5.</span>
                    <span>将来の夢は？</span>
                  </div>
                  <div className="faq-answer">
                      コンテスト活動の中で、多くの方から褒めて頂いたり、「自分ももっと頑張ってみようと思えた」と励ましの声をいただく機会があり、アナウンサーのような<strong className="has-text-marker">人前に立って発信する仕事にやりがい</strong>を感じるようになりました。<br />
                      まだ分からないことも多いのですが、<strong className="has-text-marker">より大勢の人に言葉を届けるアナウンサー</strong>という夢も視野に入れて活動していきたいと思っています。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 6  */}
        <section className="section">
          <div className="container">
            <div className="columns is-row-reverse is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                  className="image is-1by1"
                  src="/img/interview/mochizuki-youmu/dream.jpg"
                  width={640}
                  height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q6.</span>
                    <span>エントリーする方へのアドバイスは？</span>
                  </div>
                  <div className="faq-answer">
                      <strong className="has-text-marker">自分らしさを大切に最後まで頑張って欲しいです！</strong>2020年のフレキャンも楽しみにしています！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: '6rem' }}>
          <div className="container" style={{ maxWidth: 580 }}>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-left">
                      <LazyImage
                        className="image is-64x64"
                        src="/img/interview/mochizuki-youmu/dream.jpg"
                        width={640}
                        height={640}
                      />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <small>2019年準グランプリ</small>
                          <br />
                          <strong>望月優夢 (Mochizuki Youmu)</strong>
                          <br />
                          <small>'00.07.02 東京都出身</small>
                          <br />
                          <small>早稲田大学 文化構想学部</small>
                          <br />
                          14年続けているダンスを生かし、ダンスサークルを自ら立ち上げる。
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
