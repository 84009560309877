import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import shuffle from 'lodash/shuffle';
import { ResourceResult } from 'retil';
import { Onlive } from '../../types';

interface OnlivesProps {
  state: ResourceResult<Onlive[], any, any>;
  children: (list: Onlive[]) => any;
}

export function Onlives(props: OnlivesProps) {
  const { state, children } = props;
  const [initialized, setInitialized] = useState<boolean>(false);
  const [result, setResult] = useState<Onlive[]>([]);
  useEffect(() => {
    if (!state.pending) {
      setResult(shuffle(state.data));
      setInitialized(true);
    }
  }, [state.pending]);

  if (state.pending || !initialized) {
    return (
      <div className="column">
        <p className="has-text-centered">
          <a className="button is-loading is-text is-large" style={{ height: 180 }} />
        </p>
      </div>
    );
  }
  if(result.length === 0) {
      return (
          <div className="column">
              <p className="has-text-centered has-text-grey has-text-weight-bold" style={{ height: 180, lineHeight: "180px" }}>
                  現在配信中のライブはありません
              </p>
          </div>
      );
  }
  return (
    <Swiper
      {...{
        containerClass: 'onlives-container',
        mousewheel: {
          invert: true,
        },
        navigation: {
          nextEl: '.onlives-button-next',
          prevEl: '.onlives-button-prev',
        },
        slidesPerView: 'auto',
        spaceBetween: 18,
        freeMode: true,
        renderPrevButton: () => (
          <div className="onlives-button-prev">
            <div className="cover" />
            <div className="btn" />
          </div>
        ),
        renderNextButton: () => (
          <div className="onlives-button-next">
            <div className="cover" />
            <div className="btn" />
          </div>
        ),
      }}
    >
      {children(result)}
    </Swiper>
  );
}
