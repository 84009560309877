import React from 'react';
import { Entry, EntryStage, Onlive } from '@2020-contest-web/components/src/types';
import {
  Entries,
  EntryCard,
  EntryStageBreadCrumb,
  Footer,
  Header,
  SectionTitle,
} from '@2020-contest-web/components/src/components';
import classNames from 'classnames';
import { useResource } from 'retil';
import { entryList, onliveList } from '../../resources';

export interface EntryListAppProps {
  title: string;
  subtitle: string;
  stage: EntryStage;
}

export function EntryListApp({ title, subtitle, stage }: EntryListAppProps) {
  const [state] = useResource(entryList);
  const [onlives] = useResource(onliveList);
  return (
    <main className="EntryListApp">
      <Header />
      <section className="section" style={{ minHeight: '100vh' }}>
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={title} subtitle={subtitle} />
          <EntryStageBreadCrumb stage={stage} type="frecam" />
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={state}>
              {(list: Entry[]) =>
                filter(list, stage).map((item: Entry) => (
                  <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile')} key={item.entry_id}>
                    <EntryCard onlive={isOnlive(item)} entry={item} href={`/profiles/${item.entry_id}`} />
                  </div>
                ))
              }
            </Entries>
          </div>
        </div>
      </section>
      <Footer href="mailto:frecam@age.co.jp" />
    </main>
  );

  function filter(list: Entry[], stage: EntryStage) {
    switch (stage) {
      case '1':
        return list;
      case '2':
        return list.filter((item: Entry) => item.stage === '2' || item.stage === '3' || item.stage === '4' || item.stage === '5' || item.stage === '6');
      case '3':
        return list.filter((item: Entry) => item.stage === '3' || item.stage === '4' || item.stage === '5' || item.stage === '6');
      case '4':
        return list.filter((item: Entry) => item.stage === '4' || item.stage === '5' || item.stage === '6');
      case '5':
        return list.filter((item: Entry) => item.stage === '5' || item.stage === '6');
      case '6':
        return list.filter((item: Entry) => item.stage === '6');
    }
  }
  function isOnlive(entry: Entry) {
    const [onlive] = onlives.data.filter((onlive: Onlive) => onlive.entry_id == entry.entry_id);
    return !!onlive;
  }
}
