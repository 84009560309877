import React from 'react';
import classNames from 'classnames';
import { LazyImageFull, ImageState } from 'react-lazy-images';
import styled from 'styled-components';
const StepBox = styled.div`
  padding: 0;
  box-shadow: none;
  max-width: 320px;
  margin: 0 auto;
  h3,
  figure {
    margin-bottom: 2rem;
  }
  > div {
    padding: 0 2rem;
  }

  > figure > img {
    opacity: 0;
    transition: opacity 0.5s;

    &.is-load-success {
      opacity: 1;
    }
  }
`;

export default function Box(props: { boxTitle: React.ReactNode } & React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <StepBox {...props}>
      <h3 className="has-text-centered has-text-weight-bold">{props.boxTitle}</h3>
      <div>{props.children}</div>
    </StepBox>
  );
}
