import React, { useMemo, useState } from 'react';
import { Link } from 'react-navi';
import { Entry, EntryBlock, EntryStage } from '../../types';
import classNames from 'classnames';
import { useStageInfo } from '../../../src/hooks';

type StageSelection = {
  selection: { stage: EntryStage; label: string; href: string }[];
  current: { stage: EntryStage; label: string };
};

type BlockSelection = {
  selection: { block: EntryBlock; label: string; href: string }[];
  current: { block: EntryBlock; label: string };
};

function getStageName(stage: EntryStage) {
  switch (stage) {
    case '1':
      return '二次審査';
    case '2':
      return '三次審査';
    case '3':
      return '四次審査';
    case '4':
      return 'クォーターファイナル審査';
    case '5':
      return 'セミファイナル審査';
    case '6':
      return 'ファイナル審査';
  }
}

export function EntryStageBreadCrumb(props: { stage: EntryStage; type: 'frecam' | 'misscircle' }) {
  const [isStageActive, setStageActive] = useState(false);
  const [isBlockActive, setBlockActive] = useState(false);
  const stageSelection: StageSelection = useMemo(() => {
    const current = { stage: props.stage, label: getStageName(props.stage) };
    switch (props.stage) {
      case '1':
        return {
          selection: [
            { stage: '6', label: 'ファイナル審査', href: `/entries/final` },
            { stage: '5', label: 'セミファイナル審査', href: `/entries/semifinal` },
            { stage: '4', label: 'クォーターファイナル審査', href: `/entries/quarterfinal` },
            { stage: '3', label: '四次審査', href: `/entries/fourths` },
            { stage: '2', label: '三次審査', href: `/entries/thirds` },
          ],
          current,
        };
      case '2':
        return {
          selection: [
            { stage: '6', label: 'ファイナル審査', href: `/entries/final` },
            { stage: '5', label: 'セミファイナル審査', href: `/entries/semifinal` },
            { stage: '4', label: 'クォーターファイナル審査', href: `/entries/quarterfinal` },
            { stage: '3', label: '四次審査', href: `/entries/fourths` },
            { stage: '1', label: '二次審査', href: `/entries/seconds` },
          ],
          current,
        };
      case '3':
        return {
          selection: [
            { stage: '6', label: 'ファイナル審査', href: `/entries/final` },
            { stage: '5', label: 'セミファイナル審査', href: `/entries/semifinal` },
            { stage: '4', label: 'クォーターファイナル審査', href: `/entries/quarterfinal` },
            { stage: '2', label: '三次審査', href: `/entries/thirds` },
            { stage: '1', label: '二次審査', href: `/entries/seconds` },
          ],
          current,
        };
      case '4':
        return {
          selection: [
            { stage: '6', label: 'ファイナル審査', href: `/entries/final` },
            { stage: '5', label: 'セミファイナル審査', href: `/entries/semifinal` },
            { stage: '3', label: '四次審査', href: `/entries/fourths` },
            { stage: '2', label: '三次審査', href: `/entries/thirds` },
            { stage: '1', label: '二次審査', href: `/entries/seconds` },
          ],
          current,
        };
      case '5':
        return {
          selection: [
            { stage: '6', label: 'ファイナル審査', href: `/entries/final` },
            { stage: '4', label: 'クォーターファイナル審査', href: `/entries/quarterfinal` },
            { stage: '3', label: '四次審査', href: `/entries/fourths` },
            { stage: '2', label: '三次審査', href: `/entries/thirds` },
            { stage: '1', label: '二次審査', href: `/entries/seconds` },
          ],
          current,
        };
      case '6':
        return {
          selection: [
            { stage: '5', label: 'セミファイナル審査', href: `/entries/semifinal` },
            { stage: '4', label: 'クォーターファイナル審査', href: `/entries/quarterfinal` },
            { stage: '3', label: '四次審査', href: `/entries/fourths` },
            { stage: '2', label: '三次審査', href: `/entries/thirds` },
            { stage: '1', label: '二次審査', href: `/entries/seconds` },
          ],
          current,
        };
    }
  }, [props.stage]);

  return (
    <nav className="entry-breadcrumb is-margin-bottom-1">
      <div className="field is-grouped is-grouped-multiline is-align-items-center">
        <p className="control is-size-7">
          <Link href="/">Top</Link>
        </p>
        <p className="control is-size-7">/</p>
        <p className="control">
          <div
            className={classNames('dropdown', {
              'is-active': isStageActive,
            })}
          >
            <div className="dropdown-trigger">
              <button
                className="button is-rounded is-small"
                aria-haspopup="true"
                aria-controls="dropdown-menu2"
                onClick={() => toggleStageActive()}
              >
                <span>{stageSelection.current.label}</span>
                {stageSelection.selection.length > 0 && (
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  </span>
                )}
              </button>
            </div>
            {stageSelection.selection.length > 0 && (
              <div className="dropdown-menu" id="dropdown-menu2" role="menu" style={{ minWidth: '8rem' }}>
                <div className="dropdown-content">
                  {stageSelection.selection.map(({ stage, label, href }) => (
                    <Link className="dropdown-item" key={stage} href={href} onClick={() => reset()}>
                      {label}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </p>
      </div>
    </nav>
  );

  function toggleStageActive() {
    setStageActive(!isStageActive);
    setBlockActive(false);
  }

  function toggleBlockActive() {
    setStageActive(false);
    setBlockActive(!isBlockActive);
  }

  function reset() {
    setStageActive(false);
    setBlockActive(false);
  }
}

export function ProfileBreadCrumb(props: { entry: Entry }) {
  const { entry } = props;
  const { stageName, stagePath } = useStageInfo(entry.stage, entry.block);
  return (
    <nav className="entry-breadcrumb is-margin-bottom-1">
      <div className="field is-grouped is-grouped-multiline is-align-items-center">
        <p className="control is-size-7">
          <Link href="/">Top</Link>
        </p>
        <p className="control is-size-7">/</p>
        <p className="control is-size-7">
          <Link className="is-light" href={stagePath}>
            {stageName}
          </Link>
        </p>
        <p className="control is-size-7">/</p>
        <p className="control is-size-7">{entry.public_name}</p>
      </div>
    </nav>
  );
}

export function PostBreadCrumb(props: { title?: string }) {
  const { title } = props;
  return (
    <nav className="entry-breadcrumb is-margin-bottom-1">
      <div className="field is-grouped is-grouped-multiline is-align-items-center">
        <p className="control is-size-7">
          <Link href="/">Top</Link>
        </p>
        <p className="control is-size-7">/</p>
        <p className="control is-size-7">{title}</p>
      </div>
    </nav>
  );
}
