import React from 'react';

interface SectionTitleProps extends React.HTMLAttributes<HTMLDivElement> {
    title: any;
    subtitle: React.ReactNode;
    children?: any;
}

export function SectionTitle(props: SectionTitleProps) {
    return (
        <>
            <h1 className="title as-section-title has-text-centered">{props.title}</h1>
            <h2 className="subtitle" style={props.style}>{props.subtitle}</h2>
        </>
    )
}

