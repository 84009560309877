import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import LazyImage from '@2020-contest-web/components/src/components/LazyImage';

export default compose(
  mount({
    '/': route({
      getView: () => InterviewArticleApp,
      title: `2019年準グランプリ 斉藤里奈「フレキャンを本気で頑張ることで過去の自分より絶対に成長できると思います。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`
    }),
  }),
);

const InterviewArticleApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`2019年準グランプリ 斉藤里奈「フレキャンを本気で頑張ることで過去の自分より絶対に成長できると思います。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/saito-rina/ogp.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/saito-rina`} property="og:url" />
      </Helmet>
      {/* Image */}
      <section className="section as-image-section" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <div className="container">
          <figure className="image is-256by134">
            <img src="/img/interview/saito-rina/hero.jpg" />
          </figure>
        </div>
      </section>
      <div>
        {/* Question 1  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q1.</span>
                    <span>コンテストに参加したきっかけは？</span>
                  </div>
                  <div className="faq-answer">
                    母からフレキャンの存在を教えてもらい、
                    <strong className="has-text-marker">自分を変えるきっかけになるのではないか</strong>
                    と思い応募しました。また
                    <strong className="has-text-marker">
                      アナウンサーを目指しているので、コンテストがそのための力になる
                    </strong>
                    と感じたことも、応募する動機になりました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 2  */}
        <section className="section">
          <div className="container">
            <div className="columns is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/saito-rina/2019.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q2.</span>
                    <span>参加して嬉しかったことは？</span>
                  </div>
                  <div className="faq-answer">
                    こんな自分でも、<strong className="has-text-marker">たくさんの方が応援してくれたこと</strong>
                    が嬉しかったです。
                    コンテスト参加当初は、ただの大学生の私がコンテストに参加したところで「誰が応援してくれるのだろうか？」「SHOWROOMなどは誰が見てくれるだろうか？」など、様々な不安がありました。
                    <br />
                    ですが、本気でコンテストに取り組んでいくことで、
                    <strong className="has-text-marker">
                      私のために時間を割いて応援してくれたり、嬉しいことがあると私より喜んでくれる、多くの方々と知り合うことができました。
                    </strong>
                    <br />
                    普通なら知り合うことの出来なかった多くの方と知り合い、フレキャンを最後まで走り抜けることが出来たことが嬉しかったです。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 3  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q3.</span>
                    <span>審査期間中に気をつけたことは？</span>
                  </div>
                  <div className="faq-answer">
                    家族や友人やフレキャンがきっかけで知り合った方々などの
                    <strong className="has-text-marker">期待や応援に応えられるよう全力で何事にも取り組むこと</strong>
                    です。また、多くの人に見られる機会が多いので
                    <strong className="has-text-marker">美意識を高くすることや、体型維持に努めること</strong>
                    にも力を入れました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 4  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q4.</span>
                    <span>準グランプリを受賞して変わったことは？</span>
                  </div>
                  <div className="faq-answer">
                    皆様の応援のおかげで、素敵な結果を得ることが出来たことにより、
                    <strong className="has-text-marker">
                      自分が多くの方に支えられていることを意識するようになりました。
                    </strong>
                    また色々な方々の目に触れたり、新しい場面にでる機会が増えたので、
                    <strong className="has-text-marker">
                      美意識を高くして自分磨きもより一層継続して頑張るようになりました。
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Image */}
        <section className="section as-image-section">
          <LazyImage className="image" src="/img/interview/saito-rina/divider.jpg" />
        </section>
        {/*  Question 5  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q5.</span>
                    <span>将来の夢は？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">アナウンサーになることです。</strong>
                    小学生の頃からアナウンサーに憧れを持っていました。どんな番組でも活躍できるようなアナウンサーになることが目標です。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 6  */}
        <section className="section">
          <div className="container">
            <div className="columns is-row-reverse is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/saito-rina/dream.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q6.</span>
                    <span>エントリーする方へのアドバイスは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">迷うくらいなら出場する決心をして欲しい</strong>と思います！
                    始めは誰しもが不安を持っていると思いますが、必ず多くの人が味方になって最後まで応援してくれますし、運営の方々も手厚くサポートしてくれます。
                    <strong className="has-text-marker">
                      フレキャンで本気になって頑張ることで、過去の自分より絶対に成長出来ると思います。
                    </strong>
                    最終的な結果に関わらず、普通の人ではできない経験ができたり自分の力になる機会だと思うので、ぜひ挑戦してみてほしいです！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: '6rem' }}>
          <div className="container" style={{ maxWidth: 580 }}>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-left">
                      <LazyImage
                          className="image is-64x64"
                          src="/img/interview/saito-rina/dream.jpg"
                          width={640}
                          height={640}
                      />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <small>2019年準グランプリ</small>
                          <br />
                          <strong>斉藤里奈 (Saito Rina)</strong>
                          <br />
                          <small>'01.01.01 埼玉県出身</small>
                          <br />
                          <small>中央大学 文学部</small>
                          <br />
                          TVのレポーター経験もあり、将来アナウンサーを目指す。
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
