import React from 'react';
import classNames from 'classnames';

export function Footer(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <footer className={classNames("footer", props.className)}>
      <div className="content has-text-centered">
        <p className="is-size-7 is-text-futura has-text-grey">Copyright © {process.env.REACT_APP_CONTEST_TITLE} All Rights Reserved.</p>
        <a className="button is-size-7 is-light" href={props.href}>
          <span className="icon">
            <i className="far fa-envelope" />
          </span>
          <span>お問い合わせ</span>
        </a>
      </div>
    </footer>
  );
}
