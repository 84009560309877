import React from 'react';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import { isMobile } from 'is-mobile';
import { useNavigation } from 'react-navi';
const queryString = require('query-string');

const entry = require('./entry.svg');

function fbq() {
  try {
    (window as any).fbq?.('track', 'AddFriend', {
      value: 100,
      currency: 'JPY',
    });
  } catch {}
}

function line() {
  try {
    (window as any)._lt?.(
      'send',
      'cv',
      {
        type: 'AddFriend',
      },
      [process.env.REACT_APP_LINE_AD_ID],
    );
  } catch {}
}

export function FixedButtons(props: React.AnchorHTMLAttributes<HTMLAnchorElement> & { chat?: boolean }) {
  const mobile = isMobile();
  return (
    <div className={classNames("buttons as-fixed-buttons", {
        "has-chat": props.chat
    })}>
      <EntryButton />
      {!props.chat && mobile && <RecieveInfoButton />}
    </div>
  );
}

export function RecieveInfoButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement> & { chat?: boolean }) {
  return (
    <a
      {...props}
      href={process.env.REACT_APP_LINE_ENTRY_POOL}
      className="button is-dark has-text-weight-bold"
      style={{ minHeight: 50 }}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        ReactGA.event({
          category: 'Line',
          action: 'Clicked Line Button',
          label: 'Fixed Button',
        });
        fbq();
        line();
      }}
    >
      <span className="icon">
        <i className="fas fa-star" />
      </span>
      <span className="has-text-left" style={{ fontSize: 12 }}>
        エントリーに関する
        <br />
        お知らせを受け取る
      </span>
    </a>
  );
}

export function EntryButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const navigation = useNavigation();
  const { url } = navigation.getCurrentValue();
  const mobile = isMobile();
  const entryUrl = queryString.stringifyUrl({
    url: process.env.REACT_APP_LINE_ENTRY,
    query: {
      application: process.env.REACT_APP_LINE_ENTRY_APPLICATION,
      ga: process.env.REACT_APP_GA,
      ...url.query,
    },
  });
  return (
    <a
      {...props}
      href={mobile ? entryUrl : process.env.REACT_APP_LINE_ENTRY_POOL}
      className={classNames('button is-primary as-entrybutton', props.className)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        if (mobile) {
          ReactGA.event({
            category: 'Liff',
            action: 'Clicked Liff Button',
            label: 'Fixed Button',
          });
        }
        ReactGA.event({
          category: 'Line',
          action: 'Clicked Line Button',
          label: 'Fixed Button',
        });
        fbq();
        line();
      }}
    >
      <figure className="image">
        <img src={entry} alt="お知らせを受け取る" />
      </figure>
    </a>
  );
}
