import React, { SFC } from 'react'
import { videoTagString, VideoTagProps } from './videoTagString'

export const VideoTag: SFC<VideoTagProps> = props => (
    <span
        dangerouslySetInnerHTML={{
            __html: videoTagString(props),
        }}
    />
)
