import React from 'react';

export default function Box(props: React.AnchorHTMLAttributes<HTMLDivElement>) {
    return (
        <div className="box as-concept-text-box">
            <div className="box-body">
                <div className="content is-size-5">
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}
