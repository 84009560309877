import React from 'react';
import {SectionTitle} from "../SectionTitle";

export function ChanceSection(props: any) {
    return <>
        {/* Chance */}
        <section className="section has-background-white-ter" style={props.style}>
            <div className="container" style={{ maxWidth: 600 }}>
                <SectionTitle className="title" style={{ marginBottom: 60 }} title={'chance'} subtitle={'コンテスト特典'} />
                <div className="columns is-variable is-multiline is-centered is-mobile">
                    <div className="column is-4-tablet is-6-mobile">
                        <figure className="image is-1by1">
                            <img src="/img/chance1.jpg" alt="TVキー局主催などのイベント出演" />
                        </figure>
                    </div>
                    <div className="column is-4-tablet is-6-mobile">
                        <figure className="image is-1by1">
                            <img src="/img/chance2.jpg" alt="大型ファッションショー出演" />
                        </figure>
                    </div>
                    <div className="column is-4-tablet is-6-mobile">
                        <figure className="image is-1by1">
                            <img src="/img/chance3.jpg" alt="ファッション誌・カルチャー誌などの雑誌掲載" />
                        </figure>
                    </div>
                    <div className="column is-4-tablet is-6-mobile">
                        <figure className="image is-1by1">
                            <img src="/img/chance4.jpg" alt="プロカメラマンによる写真撮影" />
                        </figure>
                    </div>
                    <div className="column is-4-tablet is-6-mobile">
                        <figure className="image is-1by1">
                            <img src="/img/chance5.jpg" alt="TV番組リポーター出演" />
                        </figure>
                    </div>
                </div>
                <div className="has-text-centered">
                    <p className="is-size-7">※ 前年実績です。本年内容が変更される場合がございます</p>
                </div>
            </div>
        </section>
    </>
}