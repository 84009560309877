import React from 'react';
import styled from 'styled-components';

export function SisterContestSection({children, themeColor, src}: any) {
  return (
    <Section
      themeColor={themeColor}
      className="SisterContestSection section has-background-dark"
    >
      <div className="container">
        <h1 className="title as-section-title">
          <figure className="image">
            <img src={src} width="240" alt="姉妹コンテスト" />
          </figure>
        </h1>
        <h2 className="subtitle" style={{ marginBottom: 30 }}>
          姉妹コンテスト
        </h2>
        {children}
      </div>
    </Section>
  );
}

const Section = styled.section<{themeColor: string}>`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${props => props.themeColor};
    opacity: 0.8;
  }
`;
