import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import LazyImage from '@2020-contest-web/components/src/components/LazyImage';

export default compose(
  mount({
    '/': route({
      getView: () => InterviewArticleApp,
      title: `2019年グランプリ 中川紅葉「フレキャンに挑戦して夢へのきっかけを作って欲しいです！‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`
    }),
  }),
);

const InterviewArticleApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`2019年グランプリ 中川紅葉「フレキャンに挑戦して夢へのきっかけを作って欲しいです！‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/nakagawa-kureha/ogp.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/nakagawa-kureha`} property="og:url" />
      </Helmet>
      {/* Image */}
      <section className="section as-image-section" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <div className="container">
          <figure className="image is-256by134">
            <img src="/img/interview/nakagawa-kureha/hero.jpg" />
          </figure>
        </div>
      </section>
      <div>
        {/* Question 1  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q1.</span>
                    <span>コンテストに参加したきっかけは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">同期の友人と先輩に後押しされたので</strong>エントリーしました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 2  */}
        <section className="section">
          <div className="container">
            <div className="columns is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/nakagawa-kureha/2019.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q2.</span>
                    <span>参加して嬉しかったことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">同じような将来の目標を持つ、良い仲間と出会い、仲良くなれたこと</strong>が嬉しかったです。
                    フレキャンが終わってからも連絡を取りあう友人もできました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 3  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q3.</span>
                    <span>審査期間中に気をつけたことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">応援してくださる方たち全員とコミュニケーションが取れるよう心がけました。</strong>
                    いただいた応援メッセージには全て返したり、毎日写真付きのツイートをしたりと、応援して下さっている分、できることを全力でお返ししたいと思っていました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 4  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q4.</span>
                    <span>グランプリを受賞して変わったことは？</span>
                  </div>
                  <div className="faq-answer">
                    私生活はあまり変わらないです(笑)<br />
                    グランプリを受賞したことで、<strong className="has-text-marker">芸能関係の方に知っていただく機会が増えた</strong>と思います。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Image */}
        <section className="section as-image-section">
          <LazyImage className="image" src="/img/interview/nakagawa-kureha/divider.jpg" />
        </section>
        {/*  Question 5  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q5.</span>
                    <span>将来の夢は？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">タレントになることです。</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 6  */}
        <section className="section">
          <div className="container">
            <div className="columns is-row-reverse is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/nakagawa-kureha/dream.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q6.</span>
                    <span>エントリーする方へのアドバイスは？</span>
                  </div>
                  <div className="faq-answer">
                    大学に入って高校の時とは違い、自分のやるべきことは自分で決めなくてはならない環境になるのかなと思います。
                    私も大学に入って、将来の夢はあるのにどのように行動したらよいのか分からず悩んでいました。<br />
                    <strong className="has-text-marker">フレキャンに挑戦して夢へのきっかけを作って欲しいです！</strong><br/>
                    全力で頑張れば、必ずあなたを応援してくれる方がいると思います！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: '6rem' }}>
          <div className="container" style={{ maxWidth: 580 }}>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-left">
                      <LazyImage
                          className="image is-64x64"
                          src="/img/interview/nakagawa-kureha/dream.jpg"
                          width={640}
                          height={640}
                      />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <small>2019年グランプリ</small>
                          <br />
                          <strong>中川紅葉 (Nakagawa Kureha)</strong>
                          <br />
                          <small>'00.09.01 東京都出身</small>
                          <br />
                          <small>青山学院大学 文学部</small>
                          <br />
                          現在雑誌「Ray」専属読者モデルとして活躍中。
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
