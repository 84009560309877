import React from 'react';
import { MediaBox, MediaBoxes, SectionTitle } from '@2020-contest-web/components/src/components';

export function MediaSection() {
  return (
    <section className="section has-background-white-bis">
      <div className="container" style={{ maxWidth: 822 }}>
        <SectionTitle className="title" style={{ marginBottom: 30 }} title={'MEDIA'} subtitle={<>掲載メディア</>} />
        <MediaBoxes>
          <div style={{ maxWidth: 250 }}>
            <MediaBox
              href="https://mdpr.jp/news/detail/1896464"
              src="https://img-mdpr.freetls.fastly.net/article/8rIs/wm/8rIsZCyPwrgO-vplbLA2x49GH13GMZIgw7G-8ksvO4Q.jpg?width=700&disable=upscale&auto=webp"
              title="“日本一かわいい大学新入生”青山学院大学・中川紅葉さんに決定＜FRESH CAMPUS CONTEST 2019＞"
              media="modelpress"
              datetext="2019.11.17"
              icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
            />
          </div>
          <div style={{ maxWidth: 250 }}>
            <MediaBox
              href="https://www.oricon.co.jp/news/2148943/full/"
              src="https://contents.oricon.co.jp/upimg/news/20191118/2148943_201911180026883001574055498c.jpg"
              title="日本一かわいい“大学1年生”GPは青山学院大学・中川紅葉さん「未だに実感がわかない」"
              media="ORICON NEWS"
              datetext="2019.11.17"
              icon="https://pbs.twimg.com/profile_images/875524928769937408/L9SZjS4l_400x400.jpg"
              position="center -10px"
            />
          </div>
          <div style={{ maxWidth: 250 }}>
            <MediaBox
              href="https://actresspress.com/fresh_campus_contest20191117/"
              src="https://actresspress.com/wp-content/uploads/2019/11/fresh_campus_contest20191117thumb-1024x704.jpg"
              title="日本一かわいい大学新入生は、青学・中川紅葉に決定！「FRESH CAMPUS CONTEST 2019」＜全受賞者掲載＞ "
              media="ACTRESS PRESS"
              datetext="2019.11.18"
              icon="https://pbs.twimg.com/profile_images/701322469605396481/9im2V3VN_400x400.jpg"
            />
          </div>
          <div style={{ maxWidth: 250 }}>
            <MediaBox
              href="https://mdpr.jp/interview/detail/1900130"
              src="https://img-mdpr.freetls.fastly.net/article/AR0p/wm/AR0pIxK_ilSqlAM47ARCI20rXF11CkNR3mC2Bw9YjMI.jpg?width=700&disable=upscale&auto=webp"
              title="“日本一かわいい大学新入生”準グラ・斉藤里奈さんが圧倒的透明感！アナウンサー目指す素顔を直撃"
              media="modelpress"
              datetext="2019.11.24"
              icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
            />
          </div>
          <div style={{ maxWidth: 250 }}>
            <MediaBox
              href="https://mdpr.jp/interview/detail/1900142"
              src="https://img-mdpr.freetls.fastly.net/article/HCgS/wm/HCgSamacQGFd1d8_sYWlicxJVtbDDXYWalt8ojhrg68.jpg?width=700&disable=upscale&auto=webp"
              title="“日本一かわいい大学新入生”準グラ・望月優夢さん、出場の裏にあった「後悔」と「変化」語る"
              media="modelpress"
              datetext="2019.11.24"
              icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
            />
          </div>
          <div style={{ maxWidth: 250 }}>
            <MediaBox
              href="https://mdpr.jp/interview/detail/1900092"
              src="https://img-mdpr.freetls.fastly.net/article/VEhG/wm/VEhGGirsPYBgtPLV7h_Wea4zt8jScZbMAidy4232RiA.jpg?width=700&disable=upscale&auto=webp"
              title="“日本一かわいい大学新入生”中川紅葉さんってどんな人？「SNSのフォロワーを増やすためだけに出場したんですけど…」"
              media="modelpress"
              datetext="2019.11.17"
              icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
            />
          </div>
        </MediaBoxes>
      </div>
    </section>
  );
}
