import { useMemo } from 'react';
import {EntryBlock, EntryStage} from "../../types";

export function useStageInfo(stage: EntryStage, block: EntryBlock) {
    return useMemo(() => {
        return {
            stage,
            stageName: getStageName(stage),
            stagePath: getStagePath(stage, block)
        }
    }, [stage, block]);

    function getStageName(stage: EntryStage): string {
        switch (stage) {
            case "1":
                return "二次審査";
            case "2":
                return "三次審査";
            case "3":
                return "四次審査";
            case "4":
                return "クォーターファイナル審査";
            case "5":
                return "セミファイナル審査"
            case "6":
                return "ファイナル審査"
        }
    }

    function getStagePath(stage: EntryStage, block?: EntryBlock): string {
        const blockName = block?.toLowerCase();
        switch (stage) {
            case "1":
                return `/entries/seconds/${blockName ?? ""}`;
            case "2":
                return `/entries/thirds`;
            case "3":
                return `/entries/fourths`;
            case "4":
                return `/entries/quarterfinal`;
            case "5":
                return `/entries/semifinal`;
            case "6":
                return `/entries/final`;
        }
    }
}